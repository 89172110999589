import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Id } from '@core/http/crud-model';
import { Store } from '@ngxs/store';
import { GetProfileData } from '@data/profile/profile.actions';
import { NotificationService } from '@shared/services/notification.service';
import { DataResource } from '@shared/interfaces/data-resource';
import { ProfileResource } from '@data/profile/profile-resource.interface';
import { ProfileResourceService } from '@data/profile/profile-resource.service';
import { SetImpersonatingAs } from '@data/auth/auth.actions';
import { ProfileState } from '@data/profile/profile-state';
import { EchoService } from '@core/websockets/echo.service';
import { Router } from '@angular/router';
import { ImpersonateModalComponent } from '@pages-and-modals/impersonate-modal/impersonate-modal.component';
import { SurveyService } from '@data/survey/survey.service';

@Injectable({
  providedIn: 'root',
})
export class ImpersonateService {
  constructor(
    private dialog: MatDialog,
    private store: Store,
    private notificationService: NotificationService,
    private profileResourceService: ProfileResourceService,
    private echoService: EchoService,
    private router: Router,
    private surveyService: SurveyService
  ) {}

  openModal(): void {
    this.dialog
      .open(ImpersonateModalComponent, {
        width: '608px',
        data: {
          supervisorId: null,
        },
      })
      .afterClosed()
      .subscribe((id: Id) => {
        this.impersonate(id);
      });
  }

  finish(): void {
    this.store.dispatch(new SetImpersonatingAs(null));
    this.store.dispatch(new GetProfileData()).subscribe((res: DataResource<ProfileResource>) => {
      this.echoService.connect();
      this.surveyService.setSurvey();
      void this.router.navigate(['/', this.store.selectSnapshot(ProfileState.routeRolePrefix)]);
      this.notificationService.notifySuccess(`the-impersonation-was-finished`);
    });
  }

  private impersonate(id: Id): void {
    this.profileResourceService.impersonate(id).subscribe(
      (res: DataResource<{ header_token: string }>) => {
        this.store.dispatch(new SetImpersonatingAs(res.data.header_token));
        this.store.dispatch(new GetProfileData()).subscribe((res1: DataResource<ProfileResource>) => {
          this.echoService.connect();
          this.surveyService.reset();
          void this.router.navigate(['/', this.store.selectSnapshot(ProfileState.routeRolePrefix)]);
        });
      },
      (err) => {
        this.notificationService.notifyError('it-is-not-possible-to-log-in-as-the-selected-person');
      }
    );
  }
}
